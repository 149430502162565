import axios from "axios";

export default {
  components: {
    panel: [0, 1],
    readonly: false,
  },

  data: () => ({
    camp_dialog: false,
    campaign: {},

    insta_id: "",
    tiktok_id: "",
    youtube_id: "",

    participants: [],
    new_participants: [],
    page: 1,
    pages: 0,
    is_prev: false,
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_details();
    // this.pagination()
    // console.log(document.getElementById("loader"));

    this.insta_id = localStorage.getItem("instagramId");
    this.tiktok_id = localStorage.getItem("tiktokId");
    this.youtube_id = localStorage.getItem("youtubeId");
  },

  methods: {
    pagination() {
      setTimeout(() => {
        document
          .getElementsByClassName("v-data-table__wrapper")[0]
          .addEventListener("scroll", (event) => {
            if (
              event.target.scrollTop + event.target.offsetHeight >=
                event.target.scrollHeight &&
              this.new_participants.length !== 0
            ) {
              this.page++;
              console.log(this.page);
              this.get_details();
            }
          });
      }, 100);
    },

    async get_details() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/api/campaigns/" + this.$route.query.id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        params: {
          page: this.page,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.campaign = res.data.data.campaign;
          this.check_prev(res.data.data);

          this.pages = 2;
          this.new_participants = [];

          if (this.page === 1) {
            this.participants = res.data.data.campaign.participants;
            this.new_participants = res.data.data.campaign.participants;
            // document.getElementById("loader").scrollTo(0, 0);
          }
          if (this.page !== 1) {
            if (res.data.data.campaign.participants.length === 0) {
              this.new_participants = [];
            } else {
              this.new_participants = this.participants.concat(
                res.data.data.campaign.participants
              );

              this.participants = this.new_participants;
              console.log("!=1");
            }
            console.log(this.participants);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            this.$router.push("/signin");
          } else {
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          }
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async approve(id, status) {
      this.$store.commit("progress_status", true);
      await axios({
        method: "POST",
        url: "/api/campaigns/" + this.$route.query.id + "/" + id,
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
        data: {
          approve: status,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.campaign = res.data.data.campaign;

          document.getElementById("appr" + id).classList.add("d-none");
          document.getElementById("rej" + id).classList.add("d-none");
          if (status === true) {
            document.getElementById("res" + id).innerText = "Approved";
            document.getElementById("res" + id).classList.add("green--text");
          } else {
            document.getElementById("res" + id).innerText = "Rejected";
            document.getElementById("res" + id).classList.add("red--text");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            this.$router.push("/signin");
          } else {
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          }
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    check_prev(data) {
      console.log(this.campaign.isActive);
      console.log(this.campaign.endDate);
      if (
        this.campaign.isActive === true &&
        new Date(this.campaign.endDate).getTime() > new Date().getTime()
      ) {
        this.is_prev = true;
      }
    },

    open_link(link) {
      window.open(link, "_blank");
    },
  },
};

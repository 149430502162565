import axios from "axios";

export default {
  data: () => ({
    camp_dialog: false,

    activities: [],
    live_btn: "grey",
    prev_btn: "",
    selected: {},
    link: "",
    youtubeId: "",
  }),

  mounted() {
    window.scrollTo(0, 0);
    this.get_current();
  },

  methods: {
    async get_current() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/api/activities/current",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data.data);
          this.live_btn = "grey";
          this.prev_btn = "";
          this.activities = res.data.data.activities;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            this.$router.push("/signin");
          } else {
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          }
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    async get_prev() {
      this.$store.commit("progress_status", true);
      await axios({
        method: "GET",
        url: "/api/activities/prev",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },
      })
        .then((res) => {
          console.log(res.data);
          this.live_btn = "";
          this.prev_btn = "grey";
          this.activities = res.data.data.activities;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            this.$router.push("/signin");
          } else {
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          }
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    open_dialog(info) {
      this.camp_dialog = true;
      this.selected = info;
    },

    validation(data, ex_link) {
      if (
        data.campaign.type === 4 ||
        data.campaign.type === 5 ||
        data.campaign.type === 6 ||
        data.campaign.type === 7
      ) {
        if (!this.link || this.link.includes(" ") || !this.link.includes(".")) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Please enter a valid link.",
          });
        } else if (this.link.length > 100) {
          this.$store.commit("snackbar", {
            color: "red",
            text: "Link must contain less than 100 characters.",
          });
        } else {
          this.submit(data, ex_link);
        }
      } else {
        this.submit(data, ex_link);
      }
    },

    async submit(data, ex_link) {
      this.$store.commit("progress_status", true);
      await axios({
        method: "POST",
        url: "/api/activities/" + data._id + "/submit",
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("token"),
        },

        data: {
          link: this.link ? "https://" + this.link : "",
          youtubeId: this.youtubeId
            ? "https://youtube.com/" + this.youtubeId
            : "",
        },
      })
        .then((res) => {
          console.log(+res.data.data.wallet);
          this.campaigns = res.data.data;
          this.$store.commit("snackbar", {
            color: "green",
            text: "Thank you for your participation.",
          });
          this.$store.commit("update_info", { wallet: +res.data.data.wallet });
          this.camp_dialog = false;
          this.get_current();
          this.link = "";

          if (ex_link) {
            this.open_link(ex_link);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            this.$router.push("/signin");
          } else {
            this.$store.commit("snackbar", {
              color: "red",
              text: err.response.data.errors,
            });
          }
        })
        .finally(() => {
          this.$store.commit("progress_status", false);
        });
    },

    open_link(link) {
      window.open(link, "_blank");
    },
  },
};
